import React from "react";
import "./App.css";
import { GameComponent } from "./components/Game";

function App() {
	return (
		<>
			<GameComponent />
		</>
	);
}

export default App;
